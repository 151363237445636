import Meta from 'layouts/Meta';
import dynamic from 'next/dynamic';
const NewLanding = dynamic(() => import('routes/Landing/NewLanding'), {
    ssr: false,
});

export default function Home() {
    return (
        <>
            <Meta />
            <NewLanding />
        </>
    );
}

Home.layout = 'landing';
