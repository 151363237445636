import Head from 'next/head';
import React from 'react';

export const WebTitle = 'AlgoTest - Free Backtesting Options Trading Strategies in India';
const defaultUrl = 'https://algotest.in';
const defaultDescription =
    'AlgoTest lets you backtest options trading strategies for free in India. We provide the best backtesting platform for Banknifty, Nifty & Finnifty options strategies.';

const defaultImage = '/logo300.png';
const AppleIcon = '/logo300.png';
const twitterCreator = '@AlgoTest_in';

type MetaProps = {
    title?: string;
    description?: string;
    url?: string;
    image?: string;
    noIndex?: boolean;
};

const Meta = ({ title, description, url, image, noIndex }: MetaProps) => {
    return (
        <Head>
            <meta charSet='utf-8' />
            {noIndex && <meta name='robots' content='noindex, nofollow' />}
            <meta name='theme-color' content='#000000' />
            <meta name='msapplication-navbutton-color' content='#000000' />
            <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
            <meta name='msapplication-navbutton-color' content='#000000' />
            <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
            <meta name='language' content='English' />
            <link href='/manifest.json' rel='manifest' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
            <meta name='apple-mobile-web-app-status-bar-style' content='default' />
            <meta name='mobile-web-app-capable' content='yes' />

            <title>{title}</title>
            <meta name='title' content={title} />
            <meta name='description' content={description} />

            <link rel='icon' href={defaultImage} />
            <link rel='shortcut icon' href={defaultImage} />
            <link rel='apple-touch-icon' href={AppleIcon} />

            <meta name='twitter:card' content='summary_large_image' />
            <meta property='twitter:url' content={url} />
            <meta property='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={image || defaultImage} />
            <meta name='twitter:site' content={twitterCreator} />
            <meta name='twitter:creator' content={twitterCreator} />

            <meta property='og:type' content='website' />
            <meta property='og:url' content={url} />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={image || defaultImage} />
            <meta property='og:site_name' content='AlgoTest' />
            <link rel='canonical' href={url} />
        </Head>
    );
};

Meta.defaultProps = {
    title: WebTitle,
    description: defaultDescription,
    url: defaultUrl,
    image: defaultImage,
    noIndex: false,
};

export default React.memo(Meta);
